<template>
  <v-dialog v-model="dialog" max-width="600" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">Loan Agreement</span>
      </v-card-title>

      <v-card-text style="height: 400px">
      <br />
        <p><b>Summary</b></p>
        <PortalTarget name="loan-proposal"></PortalTarget>
       <br />
       <br />
        <p>
          By virtue of the lending Act of Jamaica, the borrower solemnly and sincerely declares that all information provided is accurate to the best of his/her knowledge. The Borrower further agrees that at the point of applying for this loan the borrower is not bankrupt or in any situation whether employment or health related that would impact repayment.
        </p>
        <p>
          The Borrower consents that all bank fees associated with account verification or repayment of loan is not the onus of Orenda Financial Services Ltd, and will be treated as the responsibility of the borrower. The Borrower agrees to have a fixed recurring amount of ______ (JMD) dollars over a period__________. With a starting date _______ until the __________, at equal or fixed payments intervals (every Fourteen (14) days). The Borrower agrees to have the relevant funds in the account to be removed on repayment date specified on loan application form, unless proof can be provided that the funds not reflecting in the account was beyond his/her control.
        </p>
        <p>
          The borrower agrees that his/her salaried account on file will not be changed without notice via text/email to Orenda Financial Services Ltd. in which The borrower will update the new salaried account before loan repayment is due.
        </p>
        <p>
          Delinquent loans that are unable to be recuperated by our collections team, where the company sees fit, may attempt to retrieve collateral of which value is sufficient to cover the outstanding amount. If an agreement can’t be reached for collateral to be collected, the company’s legal team will proceed to file an application to the court for debt recovery. The borrower will be sought to settle all fees incurred by the company and those associated with the legal proceedings, such as attorney fees and court stamp duties.
        </p>
        <p>
          Loans that are not paid in full on agreed payment date will be given a maximum of 48 hours without penalty. Failure to make full payment within 48 hours after stipulated payment date will result in a daily late fee assigned to the agreed pay back amount.
        </p>
        <p>Daily late fees are as follows;</p>
        <ol>
          <li>late fees: $150.00 (JMD)- for loans $5,000.00(JMD)</li>
          <li>
             late fees: $300(JMD)- for loans above $5,000.00(JMD) to $10,000.00(JMD)
          </li>
          <li>
            late fees: $500.00(JMD)- for loans above $10,000(JMD) to $70,000(JMD)
          </li>
        </ol>
        <p>
          Late fees that will be added shall not exceed 40% of the borrowed principal. 
        </p>
        <p>
          Orenda Financial Services takes reasonable measures to protect clients data, However the responsibility resides with clients to store and protect their password and account information. Orenda Financial Services will not be held accountable for any activities which transpire under the user account that was not authorized by the user.
        </p>
        <p>
          Information collected may be used by Orenda agents or authorized third party agencies for the purpose of debt recovery. Your information may also be provided to Credit reference agencies (CRAs) or Fraud prevention agencies (FPAs). Information may also be requested from either of the above listed agencies, along with government agencies authorized to provide such information, or any other credible social medium.
        </p>
        <p>
          Card fraud is an increasing dilemma. If anyone else has access to your card credentials and uses them for payment purposes, with or without your permission, you are responsible, and Orenda will not be liable for any losses incurred by clients due to credit card misuse or fraud.
        </p>
        <p>
          Clients of Orenda may modify contact information such as phone number, address, and email information by signing in the website and updating their profile.
        </p>
        <p>
          Orenda financial services acknowledge that our system doesn’t provide complete protection from malfunction, or delay caused by Viruses, malware, spyware or any other breach of website security. Orenda takes no responsibility for any consequences that is of resulting from the aforementioned.
        </p>
        <p>
          Once the Borrower has finished the application form and given authorization to proceed with future dated payment the borrower is legally bound to honor said agreement at the stated time
        </p>
        <p>
          Where over-payment or erroneous payment is made proof of such must be provided to Orenda, and once verified, it will be return to the client’s account via online transfer.
        </p>
        <p>
          Where an over-payment or erroneous transfer was made to client, the client agrees to return payment to Orenda Financial Services once evidence of such is provided to client.
        </p>
        <p>
          Orenda Financial Services, only refunds clients that have been successfully approved. Approved clients must verify their salaried account through card pre-authorization. Orenda will only refund in equal amount that which was paid to Orenda, once proof of such has been provided. N.B. Any additional bank fees, or charges billed to client's accounts, by his/her bank during the account verification process is not an onus of Orenda and will be the sole responsibility of the client.
        </p>
        <p>
          No cancellation once client authorizes transaction and the funds disbursed.
        </p>
        <p>
          Clients are urged to Check and validate that the account information is correct and is assigned to the client. The onus will not be on Orenda if the incorrect account details are provided on the application. It will be the sole responsibility of the client to reimburse Orenda Financial Services Ltd in full amount if an erroneous transfer was made due to incorrect information provided on their application form.
        </p>
        <p>
          All clients that have requested for payment extension until the next payday will be assigned a fixed roll-over fee of JMD $ 2,000.00, which must be paid through the “pay now” Button on the landing page, or via electronic transfer.
        </p>
        <p>
          All Late fees will be paid through the “pay now” Button on the landing page, or via electronic transfer.
        </p>
        <p>
          <b>
            Method of communication
          </b>
        </p>
        <p>
          Information will be communicated through clients email and personal number via text message
        </p>
        <p>
          All clients of Orenda Financial Services Ltd (Orenda) agree to be bound by the terms of use of this website. If you do not agree with the terms and conditions stipulated you should refrain from using this website.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :disabled="loading"
          color="orange darken-1"
          @click="$emit('input', false)"
        >
          Disagree
        </v-btn>

        <v-btn
          color="green darken-1"
          :disabled="loading"
          :loading="loading"
          @click="$emit('ok')"
        >
          Agree
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "LoanAgreement",
  props: ["value", "loading"],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    value: function (val) {
      this.dialog = val;
    },
  },
};
</script>