<template>
  <div>
    <v-card class="mx-auto" max-width="800">
      <v-card-title>Loan Application</v-card-title>
      <v-card-text v-if="invalidKeys.length === 0">
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row no-gutters>
              <v-col>
                <v-card class="mx-auto" outlined>
                  <v-list-item>
                    <v-list-item-content class="justify-center">
                      <v-radio-group v-model="userType" row>
                        <v-radio
                          v-for="type in USER_TYPES"
                          :key="type.value"
                          :label="type.text"
                          :value="type.value"
                        ></v-radio>
                      </v-radio-group>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="loanRequired"
                  :rules="amountRules"
                  :counter="25"
                  label="Amount Required"
                  required
                  type="number"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-menu
                  v-model="isDateRequiredMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_required"
                      label="Date Required"
                      :rules="dateRules"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_required"
                    @input="isDateRequiredMenu = false"
                    :max="maxDateRequired"
                    :min="minDateRequired"
                    required
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="loanType"
                  label="Loan Type"
                  required
                  :rules="[(v) => !!v || 'Loan Type is required']"
                  :items="loanTypes"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="payment_method"
                  label="Payment Method"
                  :disabled="true"
                  required
                  :rules="[(v) => !!v || 'Method is required']"
                  item-text="text"
                  item-value="value"
                  :items="[
                    {
                      value: 'CardPreauthorisation',
                      text: 'Card Pre-Authorisation',
                    },
                    { value: 'StandingOrder', text: 'StandingOrder' },
                    { value: 'EFT', text: 'Bank Transfer' },
                  ]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-slider
                  v-model="days"
                  thumb-label="always"
                  label="Loan Period(Days)"
                  :max="maxDays"
                  :min="minDays"
                ></v-slider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="reason"
                  label="Reason for loan?"
                  :rules="[(v) => !!v || 'Reason is required']"
                  :counter="120"
                  required
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12">
                <v-btn
                  :color="valid ? 'success' : 'error'"
                  class="mr-4"
                  :loading="loading"
                  :disabled="loading"
                  @click="activateAgreement"
                  >Submit
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <portal to="loan-proposal" :disabled="!agreementVisible">
              <v-row no-gutters>
                <v-col cols="4">
                  <v-subheader class="underline"> Principal </v-subheader>
                  <div class="bold">
                    {{ parseFloat(loanRequired) | toCurrency }}
                  </div>
                </v-col>

                <v-col cols="4">
                  <v-subheader class="underline">{{
                    results.InstallmentLabel
                  }}</v-subheader>
                  <div class="bold">{{ results.Installment | toCurrency }}</div>
                </v-col>

                <v-col cols="4">
                  <v-subheader class="underline">Total Interest</v-subheader>
                  <div class="bold">
                    {{ results.TotalInterest | toCurrency }}
                  </div>
                </v-col>

                <v-col cols="4">
                  <v-subheader class="underline">Total Payback</v-subheader>
                  <div class="bold">
                    {{ results.totalPayback | toCurrency }}
                  </div>
                </v-col>

                <v-col cols="4">
                  <v-subheader class="underline">No of Paybacks</v-subheader>
                  <div class="bold">{{ this.terms }}</div>
                </v-col>

                <v-col cols="4">
                  <v-subheader class="underline">{{
                    results.interestRateLabel
                  }}</v-subheader>
                  <div class="bold">
                    {{ results.InterestRate | formatPercent }}
                  </div>
                </v-col>

                <v-col cols="4">
                  <v-subheader class="underline">Processing Fees</v-subheader>
                  <div class="bold">
                    {{ results.ProcessingFees | toCurrency }}
                  </div>
                </v-col>

                <v-col cols="4">
                  <v-subheader class="underline">Daily Interest</v-subheader>
                  <div class="bold">
                    {{ results.DailyInterest | toCurrency }}
                  </div>
                </v-col>
                <v-col>
                  <v-subheader class="bold underline"
                    >Effective Annual Interest Rate</v-subheader
                  >
                  <div class="bold">
                    {{ effectiveAnnualInterestRate }}
                  </div>
                </v-col>
              </v-row>
            </portal>
          </v-container>
        </v-form>
      </v-card-text>
      <template v-else>
        <v-card-text>
          <p>Before applying for a loan;</p>
          <ul>
            <li>Your profile must be properly filled out.</li>
            <li>Your Email and Phone Numbers must be certified</li>
          </ul>
          <p>
            The follwowing
            <span>{{
              invalidKeys.length == 1 ? "area is " : "areas are "
            }}</span
            >not valid;
          </p>
          <ol>
            <li v-for="key in invalidKeys" :key="key">
              <b>{{ key }}</b>
            </li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" to="/member/profile?edit=true"
            >Click to Check Profile
          </v-btn>
          <v-btn color="primary" @click="showCredentials"
            >Click to Check Credentials
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
    <loan-agreement
      v-model="agreementVisible"
      :loading="loading"
      @ok="createLoan"
    />
  </div>
</template>

<script>
import graphqlMixin from "@/mixins/graphql-mixin.js";
import settingMixins from "@/mixins/settingMixins";
import { EventBus } from "@/event-bus";
import LoanAgreement from "../LoanAgreement.vue";

export default {
  components: { LoanAgreement },
  mixins: [graphqlMixin, settingMixins],
  data: () => ({
    agreementVisible: false,

    loanTypes: [],

    valid: false,
    loading: false,

    dateRules: [(v) => !!v || "Date is required"],
    date_required: null,
    reason: "",
    payment_method: "CardPreauthorisation",

    isDateRequiredMenu: false,
  }),
  filters: {
    formatPercent(value) {
      return `${value}%`;
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {
    invalidKeys() {
      return this.$store.getters["profile/invalidKeys"];
    },
    checkPersonalInfo() {
      return this.$store.getters["profile/checkPersonalInfo"];
    },
    maxDateRequired() {
      const sixtyDays = 60 * 24 * 60 * 60 * 1000;
      return new Date(Date.now() + sixtyDays).toISOString().slice(0, 10);
    },
    minDateRequired() {
      return new Date().toISOString().slice(0, 10);
    },
    amountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) =>
          (v >= this.loanMin && v <= this.loanMax) ||
          `Minimum Amount is ${this.loanMin}, Maximum is ${this.loanMax}`,
      ];
    },
  
  },
  watch: {},
  mounted() {
    this.$store.dispatch("settings/getLoanSettings");
    this.$store.dispatch("profile/getPersonalInfo");
  },
  methods: {
    showCredentials() {
      EventBus.$emit("show-credentials");
    },
    activateAgreement() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.agreementVisible = true;
    },
    async createLoan() {
      //this.$refs.form[0].validate();
      this.$refs.form.validate();
      if (!this.valid) return;
      try {
        this.loading = true;

        const model = {
          borrower_type: this.userType,
          reason: this.reason,
          amount: parseFloat(this.loanRequired),
          date_required: this.date_required,
          term: 1,
          days: this.days,
          periodType: this.loanType === "Term" ? "monthly" : "fortnightly",
          type: this.loanType,
          payment_method: this.payment_method,
          currency: "JMD",
        };

        console.log("loanModel", model);
        await this.$store.dispatch("loan/createLoan", model);
        this.$router.push({ path: "loans" });
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
  text-align: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>