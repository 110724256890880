const fortnightDays = 14;
const USER_TYPES = [
  { value: "NON_BPO", text: "BPO Management" }, //changed Non BPO to BPO Management
  { value: "BPO_BASIC", text: "BPO:Agent" },
  { value: "BPO_ADVANCED", text: "BPO Salary Deduction" }, //changed None agent to BPO Salary Deduction
];

export default {
  data() {
    return {
      loanType: "",
      loanRequired: 0,
      USER_TYPES,
      userType: USER_TYPES[0].value,
      fortnightDays,
      days: 2 * fortnightDays,
      results: {
        InterestRate: 0,
        ProcessingFees: 0,
        DailyInterest: 0,
        TotalInterest: 0,
        Installment: 0,
        totalPayback: 0,
      },
      loanTypes: [],
      setting: {},
      settingsLoading: false
    };
  },

  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },

    loanMax() {
      switch (this.loanType) {
        case "PayDay":
          return this.setting.payday_daily_max_amount;
        case "PayDaySpecial":
          return this.setting.oneoff_max_amount;
        default:
          return this.setting.term_max_amount;
      }
    },

    loanMin() {
      switch (this.loanType) {
        case "PayDay":
          return this.setting.payday_daily_min_amount;
        case "PayDaySpecial":
          return this.setting.oneoff_min_amount;
        default:
          return this.setting.term_min_amount;
      }
    },

    minDays() {
      switch (this.loanType) {
        case "PayDay":
          return this.setting.min_days_payday;
        case "PayDaySpecial":
          return this.setting.min_days_oneoff;
        default:
          return this.setting.min_days_term;
      }
    },

    maxDays() {
      switch (this.loanType) {
        case "PayDay":
          return this.setting.max_days_payday;
        case "PayDaySpecial":
          return this.setting.max_days_oneoff;
        default:
          return this.setting.max_days_term;
      }
    },

    interestRateLabel() {
      return this.loanType === "PayDaySpecial"
        ? "One Off Intetest Rate"
        : "Daily Interest Rate";
    },

    terms() {
      /*  return this.loanType === "Term"
        ? Math.floor(this.days / fortnightDays)
        : 1; */
      const days = this.days;
      return (this.setting.paydays || []).findIndex((x) => days <= x) + 1;
    },

    effectiveAnnualInterestRate() {
      const {
        results: { ProcessingFees, TotalInterest },
        loanRequired,
        days,
      } = this;
      if (!loanRequired) return 0;
      return (
        Math.round(
          ((TotalInterest + ProcessingFees) / loanRequired / days) * 365 * 100
        ) / 100
      );
    },
  },
  watch: {
    userType: function () {
      this.setSettings();
      this.calculateLoanTerms();
    },

    settings: {
      handler: function () {
        this.setSettings();
        this.calculateLoanTerms();
      },
      deep: true,
    },

    loanRequired: function () {
      // this.setInitialParamaters();
      //this.$nextTick(() =>
      this.calculateLoanTerms(); //);
    },

    loanType: function (val) {
      //  this.setInitialParamaters();
      //this.$forceUpdate();
      // this.$nextTick(() => this.calculateLoanTerms());
      this.setSettings(val);
      this.calculateLoanTerms();
    },
    days: function () {
      this.calculateLoanTerms();
    },
  },
  mounted() {
    this.getSettings()
  },

  methods: {
    async getSettings() {
      try {
        this.settingsLoading = true
        await this.$store.dispatch("settings/getLoanSettings");
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.settingsLoading = false
      }
    },
    setSettings(loanType) {
      console.log("this.settings:", this.settings)
      this.setting =
        (this.settings || []).find((x) => x.userType === this.userType) || {};

      this.loanTypes = [];
      if (this.setting.oneoff_active) {
        this.loanTypes.push({
          value: "PayDaySpecial",
          text: "Special Pay Day",
        });
      }

      if (this.setting.payday_daily_active) {
        this.loanTypes.push({ value: "PayDay", text: "Pay Day" });
      }

      if (this.setting.term_active) {
        this.loanTypes.push({ value: "Term", text: "Term" });
      }

      loanType = loanType || this.loanType;
      if (!this.loanTypes.find((x) => x.value === loanType)) {
        loanType = null;
      }
      this.loanType = loanType || this.loanTypes[0].value;
    },

    calculateLoanTerms() {
      switch (this.loanType) {
        case "PayDaySpecial":
          //  this.results.terms = 1;
          this.results.interestRateLabel = "One Off Intetest Rate";
          this.results.InstallmentLabel = "Installment";
          this.results.InterestRate = this.setting.payday_oneoff_interest_rate;
          this.results.ProcessingFees = 0;
          this.results.DailyInterest = 0;
          this.results.TotalInterest =
            (this.setting.payday_oneoff_interest_rate *
              parseFloat(this.loanRequired)) /
            100;
          this.results.totalPayback =
            this.results.TotalInterest + parseFloat(this.loanRequired);
          this.results.Installment = this.results.totalPayback;
          break;
        case "PayDay": {
          //   this.results.terms = 1;
          this.results.interestRateLabel = "Daily Interest Rate";
          this.results.InstallmentLabel = "Installment";
          this.results.InterestRate = this.setting.payday_daily_interest_rate;
          this.results.ProcessingFees = this.setting.processing_fee;
          this.results.DailyInterest =
            (this.setting.payday_daily_interest_rate *
              parseFloat(this.loanRequired)) /
            100;
          this.results.TotalInterest = this.results.DailyInterest * this.days;
          this.results.totalPayback =
            this.setting.processing_fee +
            this.results.TotalInterest +
            parseFloat(this.loanRequired);
          this.results.Installment = this.results.totalPayback;
          break;
        }

        case "Term":
          this.results.interestRateLabel = "Daily Interest Rate";
          this.results.InstallmentLabel = "Installment";
          this.results.InterestRate = this.setting.term_daily_interest_rate;
          this.results.ProcessingFees = this.setting.processing_fee;
          this.results.DailyInterest =
            (this.setting.term_daily_interest_rate *
              parseFloat(this.loanRequired)) /
            100;
          this.results.TotalInterest = this.results.DailyInterest * this.days;
          this.results.totalPayback =
            this.setting.processing_fee +
            this.results.TotalInterest +
            parseFloat(this.loanRequired);
          this.results.Installment = this.results.totalPayback / this.terms;
          //  this.results.terms = Math.floor(this.days / fortnightDays);
          break;
      }

      /// this.setting.
    },
  },
};
